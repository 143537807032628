import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { calculatePrice } from '@/data/products';
import { calculateDmarcPrice } from '@/data/dmarc-products';

const Context = createContext();

export const StateContext = ({ children }) => {
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const savedCart = localStorage.getItem('cartItems');
        const savedPrice = localStorage.getItem('totalPrice');
        const savedQuantities = localStorage.getItem('totalQuantities');

        if (savedCart) setCartItems(JSON.parse(savedCart));
        if (savedPrice) setTotalPrice(parseFloat(savedPrice));
        if (savedQuantities) setTotalQuantities(parseInt(savedQuantities));
      } catch (error) {
        console.error('Error loading cart data:', error);
      } finally {
        setLoaded(true);
      }
    }
  }, []);

  useEffect(() => {
    if (loaded && typeof window !== 'undefined') {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      localStorage.setItem('totalPrice', totalPrice.toString());
      localStorage.setItem('totalQuantities', totalQuantities.toString());
    }
  }, [cartItems, totalPrice, totalQuantities, loaded]);

  const onAdd = (product, quantity, options = {}) => {
    if (product.skipPriceCalculation && 'finalPrice' in product) {
      const newCartItem = {
        ...product,
        uniqueId: `${product.id}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        quantity,
        price: product.finalPrice,
      };

      setCartItems((prevItems) => [...prevItems, newCartItem]);
      setTotalPrice((prevTotal) => prevTotal + newCartItem.price);
      setTotalQuantities((prevQuantities) => prevQuantities + quantity);

      toast.success(`${product.name} added to cart`);
      return;
    }

    const uniqueId = `${product.id}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    const newCartItem = {
      ...product,
      uniqueId,
      quantity,
      options,
      price:
        product.type === 'dmarc'
          ? calculateDmarcPrice(product, options).total
          : calculatePrice(product, options).total,
    };

    setCartItems((prevItems) => [...prevItems, newCartItem]);
    setTotalPrice((prevTotal) => prevTotal + newCartItem.price);
    setTotalQuantities((prevQuantities) => prevQuantities + quantity);

    toast.success(`${product.name} added to cart`);
  };

  const onAddDmarc = (product, quantity, selectedOption) => {
    const uniqueId = `${product.id}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    const newCartItem = {
      ...product,
      uniqueId,
      quantity,
      selectedOption,
      price: calculateDmarcPrice(product, { selectedOption }).total,
    };

    setCartItems((prevItems) => [...prevItems, newCartItem]);
    setTotalPrice((prevTotal) => prevTotal + newCartItem.price);
    setTotalQuantities((prevQuantities) => prevQuantities + quantity);

    toast.success(`${product.name} added to cart`);
  };

  const onRemove = (cartItem) => {
    const updatedCartItems = cartItems.filter((item) => item.uniqueId !== cartItem.uniqueId);

    setCartItems(updatedCartItems);
    setTotalPrice((prevTotal) => prevTotal - cartItem.price);
    setTotalQuantities((prevQuantities) => prevQuantities - cartItem.quantity);
  };

  const toggleCartItemQuantity = (id, value) => {
    const foundItem = cartItems.find((item) => item.uniqueId === id);
    const index = cartItems.findIndex((item) => item.uniqueId === id);

    if (!foundItem) return;

    const newCartItems = [...cartItems];

    if (value === 'inc') {
      newCartItems[index] = {
        ...foundItem,
        quantity: foundItem.quantity + 1,
      };
      setCartItems(newCartItems);
      setTotalPrice((prevTotal) => prevTotal + foundItem.price);
      setTotalQuantities((prevQty) => prevQty + 1);
    } else if (value === 'dec') {
      if (foundItem.quantity > 1) {
        newCartItems[index] = {
          ...foundItem,
          quantity: foundItem.quantity - 1,
        };
        setCartItems(newCartItems);
        setTotalPrice((prevTotal) => prevTotal - foundItem.price);
        setTotalQuantities((prevQty) => prevQty - 1);
      }
    }
  };

  const clearCart = () => {
    setCartItems([]);
    setTotalPrice(0);
    setTotalQuantities(0);

    // Clear localStorage
    if (typeof window !== 'undefined') {
      localStorage.removeItem('cartItems');
      localStorage.removeItem('totalPrice');
      localStorage.removeItem('totalQuantities');
    }
  };

  return (
    <Context.Provider
      value={{
        showCart,
        setShowCart,
        cartItems,
        totalPrice,
        totalQuantities,
        loaded,
        onAdd,
        onAddDmarc,
        onRemove,
        toggleCartItemQuantity,
        clearCart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
